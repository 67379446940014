import {Injectable} from '@angular/core';
import {BaseApiService} from './base-api.service';
import {Observable} from 'rxjs';
import {ApiModule} from './api.module';
import {HttpParams} from '@angular/common/http';
import {AutoTopupModel} from '../../profile/shared/profile.model';
import {map} from 'rxjs/operators';
import {CampaignOfferModel, CampaignType, RtmActivationRequestModel, RtmDirectDataModel} from '@myvf/shared';
import {MoneyManagementPayloadModel} from '../../shared/money-management-form/money-management-form/money-management.model';

@Injectable({
  providedIn: ApiModule,
  deps: [BaseApiService]
})
export class SimApiService {
  constructor(private http: BaseApiService) {
  }

  details(msisdn: string) {
    return this.http.get(`/api/v3/sim/${msisdn}`);
  }

  detailsPost(msisdn: string, data: any): Observable<any> {
    return this.http.post(`/api/v3/sim/${msisdn}`, data);
  }

  puk(msisdn: string): Observable<any> {
    return this.http.get(`/api/v3/sim/${msisdn}/puk`);
  }

  setConsents(msisdn: string, data: any): Observable<any> {
    return this.http.post(`/api/v3/sim/${msisdn}/agreements`, data);
  }

  getConsents(msisdn: string, isSetup: string): Observable<any> {
    const params = new HttpParams().set('isSetup', isSetup);
    return this.http.get(`/api/v3/sim/${msisdn}/agreements`, params);
  }

  /**
   * Get the MoneyManagement encrypted data
   * @param msisdn the user's phonenumber
   * @param payload body to be encrypted. Typed according to MoneyManagementPayloadModel
   */
  setMoneyManagementToken(
    msisdn: string,
    payload: MoneyManagementPayloadModel
  ): Observable<any> {
    // return this.http.post(`/api/v3/sim/${msisdn}/payment/crypt`, {data: payload});
    return this.http.post(`/api/v3/sim/${msisdn}/payment/signature`, {
      ...payload
    });
  }

  /**
   * Get tokenized credit card PAN (primary account number)
   * @param msisdn msisdn the user's phonenumber
   * @param token the encrypted transaction identifier sent by MoneyManagement
   */
  getTokenizedPan(msisdn: string, token: string): Observable<any> {
    return this.http.get(`/api/v3/sim/${msisdn}/payment/${token}`);
  }

  sendMail(msisdn: string, data: any): Observable<any> {
    return this.http.post(`/api/v4/sim/${msisdn}/offer/sendEmail`, data);
  }

  /**
   * Get invoices list using customerCode of requesting user  - [INVOICES JOURNEY]
   * wiki https://vodafone-it.atlassian.net/wiki/spaces/DXL/pages/1276477443/Journey+Specification+-+Invoices+Journey
   */
  getInvoicesList(msisdn: string, simType: string, noLoader?: boolean): Observable<any> {
    let params = new HttpParams();

    if (noLoader) {
      params = new HttpParams().set('noLoader', 'true');
    }

    return this.http.get(`/api/v3/${simType}/${msisdn}/invoices`, params);
  }

  getCreditCardData(msisdn: string): Observable<any> {
    return this.http.get(`/api/v3/sim/${msisdn}/payment/cardData`);
  }

  topupHistoryV3(
    msisdn: string,
    startDate: string,
    endDate: string
  ): Observable<any> {
    const params = new HttpParams().set('s', startDate).set('e', endDate);

    return this.http.get(`/api/v3/sim/${msisdn}/cost/topups`, params);
  }

  topupHistoryV4(
    msisdn: string,
    startDate: string,
    endDate: string
  ): Observable<any> {
    const params = new HttpParams().set('s', startDate).set('e', endDate);

    return this.http.get(`/api/v4/sim/${msisdn}/cost/topup`, params);
  }

  costsTotalV3(
    msisdn: string,
    startDate: string,
    endDate: string
  ): Observable<any> {
    const params = new HttpParams().set('s', startDate).set('e', endDate);

    return this.http.get(`/api/v3/sim/${msisdn}/cost/total`, params);
  }

  costsDetailV3(
    msisdn: string,
    startDate: string,
    endDate: string,
    mn: string,
    types: string,
    aggregated: string
  ): Observable<any> {
    const params = new HttpParams()
      .set('s', startDate)
      .set('e', endDate)
      .set('mn', mn)
      .set('types', types)
      .set('aggregation', aggregated);

    return this.http.get(`/api/v3/sim/${msisdn}/cost`, params);
  }

  costsTotalV4(
    msisdn: string,
    startDate: string,
    endDate: string
  ): Observable<any> {
    const params = new HttpParams().set('s', startDate).set('e', endDate);

    return this.http.get(`/api/v4/sim/${msisdn}/cost/total`, params);
  }

  costsDetailV4(
    msisdn: string,
    startDate: string,
    endDate: string,
    mn: string,
    types: string,
    aggregated: string,
    noLoader?: boolean
  ): Observable<any> {
    let params = new HttpParams()
      .set('s', startDate)
      .set('e', endDate)
      .set('mn', mn)
      .set('types', types)
      .set('aggregation', aggregated);

    if (noLoader) {
      params = params.set('noLoader', 'true');
    }

    return this.http.get(`/api/v4/sim/${msisdn}/cost`, params);
  }

  usageTotal(
    msisdn: string,
    startDate: string,
    endDate: string,
    noLoader?: boolean
  ): Observable<any> {
    let params = new HttpParams().set('s', startDate).set('e', endDate);

    if (noLoader) {
      params = params.set('noLoader', 'true');
    }

    return this.http.get(`/api/v4/sim/${msisdn}/cost/usage`, params);
  }

  /**
   * Get family list component for Giga Family
   * @param msisdn msisdn the user's phonenumber
   */
  getFamily(msisdn: string) {
    return this.http.get(`/api/v3/sim/${msisdn}/family`);
  }

  /**
   * catalog api get product data
   * @param msisdn current msisdn
   * @param productId product id
   * @param showCaseId show case id
   */
  product(msisdn: string, productId: string, showCaseId = ''): Observable<any> {
    return this.http.get(
      `/api/v4/sim/${msisdn}/offer/activable/${productId}?showCase=${showCaseId}`
    );
  }

  /**
   * catalog api get product data
   * @param msisdn current msisdn
   * @param productId product id
   */
  productActive(msisdn: string, productId: string): Observable<any> {
    return this.http.get(`/api/v4/sim/${msisdn}/offer/active/${productId}`);
  }

  /**
   * catalog api get product data
   * @param msisdn current msisdn
   * @param productId product id
   */
  productVas(msisdn: string, productId: string): Observable<any> {
    return this.http.get(`/api/v4/sim/${msisdn}/offer/active/nvvas`).pipe(
      map(data => ({
        code: data.code,
        description: data.description,
        result: data.result.section[0].products.find(p => p.id === productId)
      }))
    );
  }

  /**
   * catalog api get activable offers
   * @param msisdn current msisdn
   * @param showCaseId show case id
   */
  offers(msisdn: string, showCaseId = ''): Observable<any> {
    return this.http.get(
      `/api/v4/sim/${msisdn}/offer/activable?showCase=${showCaseId}`
    );
  }

  /**
   * catalog api get data for check activation
   * @param msisdn current msisdn
   * @param productId product id
   * @param source source
   */
  checkActivation(
    msisdn: string,
    productId: string,
    source: string = ''
  ): Observable<any> {
    return this.http.get(
      `/api/v4/sim/${msisdn}/offer/checkActivation/${productId}?source=${source}`
    );
  }

  /**
   * catalog api perform an offer activation
   * @param msisdn current msisdn
   * @param body request body
   * @param source source
   * @param type optional query param
   */
  performActivation(
    msisdn: string,
    body: object,
    source: string = '',
    type?: string
  ): Observable<any> {
    const queryParam = type ? `&type=${type}` : '';
    return this.http.post(
      `/api/v4/sim/${msisdn}/offer/performActivation?source=${source}${queryParam}`,
      body
    );
  }

  /**
   * catalog api perform an offer activation for info attivazione products
   * @param msisdn current msisdn
   * @param productId product id
   * @param body request body
   * @param source source
   * @param type optional query param
   */
  performActivationInfoAttivazione(msisdn: string, productId: string, body: object, source: string = '', type?: string): Observable<any> {
    const queryParam = type ? `&type=${type}` : '';
    return this.http.post(`/api/v4/sim/${msisdn}/offer/performNbaMultiproduct/${productId}?source=${source}${queryParam}`, body);
  }

  /**
   * catalog api get active offers
   * @param msisdn current msisdn
   */
  activeOffers(msisdn: string): Observable<any> {
    return this.http.get(`/api/v4/sim/${msisdn}/offer/active`);
  }

  /**
   * catalog api get active offers vas no vodafone
   * @param msisdn current msisdn
   */
  activeOffersVasNoVodafone(msisdn: string): Observable<any> {
    const params = new HttpParams()
      .set('noLoader', 'true');
    return this.http.get(`/api/v4/sim/${msisdn}/offer/active/nvvas?noLoader=true`, params);
  }

  /**
   * catalog api check for deactivation
   * @param msisdn current msisdn
   * @param productId product id
   */
  checkDeactivation(msisdn: string, productId: string): Observable<any> {
    return this.http.get(
      `/api/v4/sim/${msisdn}/offer/checkDeactivation/${productId}`
    );
  }

  /**
   * catalog api perform an offer deactivation
   * @param msisdn current msisdn
   * @param body
   */
  performDeactivation(msisdn: string, body: any): Observable<any> {
    return this.http.post(
      `/api/v4/sim/${msisdn}/offer/performDeactivation`,
      body
    );
  }

  /**
   * catalog api perform a vas deactivation
   * @param msisdn current msisdn
   * @param body
   */
  performVasDeactivation(msisdn: string, body: any): Observable<any> {
    return this.http.post(`/api/v4/sim/${msisdn}/offer/deactivate/vas`, body);
  }

  /**
   * catalog api check for perform an early renewal
   * @param msisdn current msisdn
   * @param productId product id
   */
  checkEarlyRenewal(msisdn: string, productId: string): Observable<any> {
    return this.http.get(
      `/api/v4/sim/${msisdn}/offer/checkEarlyRenewal/${productId}`
    );
  }

  /**
   * catalog api perform an offer renwal
   * @param msisdn current msisdn
   * @param productId product id
   */
  performEarlyRenewal(msisdn: string, productId: string): Observable<any> {
    return this.http.get(
      `/api/v4/sim/${msisdn}/offer/performEarlyRenewal/${productId}`
    );
  }

  /**
   * automatic topup api for get status and data
   * @param msisdn current msisdn
   */
  getAutoTopup(msisdn: string) {
    return this.http.get(`/api/v3/sim/${msisdn}/variableOffer/topup`);
  }

  /**
   * automatic topup api for set configured data
   * @param msisdn current msisdn
   * @param autoTopupData data configured by user
   */
  setAutoTopup(msisdn: string, autoTopupData: AutoTopupModel): Observable<any> {
    return this.http.post(
      `/api/v3/sim/${msisdn}/variableOffer/topup`,
      autoTopupData
    );
  }

  getBillingInfo(msisdn: string): Observable<any> {
    return this.http.get(`/api/v3/sim/${msisdn}/billing/info`);
  }

  setBillingInfo(msisdn: string, billingInfoData: any) {
    return this.http.post(`/api/v3/sim/${msisdn}/billing/paymentmethod`,{...billingInfoData});
  }

  setBillingInfoCurrent(msisdn: string, method: string) {
    return this.http.post(`/api/v3/sim/${msisdn}/billing/current/${method}`);
  }

  /**
   * Given a rtm offer(direct activation) to activate, it allows to check the activation status by
   * recovering any incompatibilities.
   * @param msisdn the user's phonenumber
   * @param rtmActivationRequest body request
   */
  checkRtmActivation(
    msisdn: string,
    rtmActivationRequest: RtmDirectDataModel
  ): Observable<any> {
    return this.http.post(
      `/api/v4/sim/${msisdn}/offer/checkRtmActivation`,
      rtmActivationRequest
    );
  }

  /**
   * Given a rtm offer to activate, it allows to perform the activation.
   * @param msisdn the user's phonenumber
   * @param request body request
   */
  performRtmActivation(
    msisdn: string,
    request: RtmActivationRequestModel
  ): Observable<any> {
    return this.http.post(
      `/api/v4/sim/${msisdn}/offer/performRtmActivation`,
      request
    );
  }

  /**
   * Given a campaign offer to activate, it allows to check the activation status by recovering any incompatibilities.
   * @param msisdn the user's phonenumber
   * @param campaignOffer body request
   * @param type optional query param
   */
  checkCampaignActivation(
    msisdn: string,
    campaignOffer: CampaignOfferModel,
    type?: CampaignType
  ): Observable<any> {
    const queryParam = type ? `?type=${type}` : '';
    return this.http.post(
      `/api/v4/sim/${msisdn}/offer/checkCampaignActivation${queryParam}`,
      campaignOffer
    );
  }

  /**
   * return information for recharge links
   * @param msisdn the user's phonenumber
   */
  topupButton(msisdn: string): Observable<any> {
    return this.http.get(`/api/v3/sim/${msisdn}/variableOffer/topupButton`);
  }

  lineup(msisdn: string, bundleId: string): Observable<any> {
    return this.http.get(
      `/api/v3/sim/${msisdn}/variableOffer/lineup/${bundleId}`
    );
  }

  lineupActivation(msisdn: string, body: any): Observable<any> {
    return this.http.post(`/api/v3/sim/${msisdn}/variableOffer/lineup`, body);
  }

  lineupDelete(msisdn: string, bundleId: string): Observable<any> {
    return this.http.delete(
      `/api/v3/sim/${msisdn}/variableOffer/lineup/${bundleId}`
    );
  }

  /**
   * Check consistency offer
   * @param msisdn msisdn number
   * @param body {offerIn: offers user should have, offerOut: offers user should NOT have}
   */
  checkConsistency(msisdn: string, body: any): Observable<any> {
    const {offerIn, offerOut} = body;
    let params = new HttpParams();

    Object.keys({offerIn, offerOut}).forEach((v) => {
      params = params.set(v, body[v]);
    });

    return this.http.get(`/api/v4/sim/${msisdn}/offer/checkConsistency`, params);
  }
}
