import AppStart from '../../../mocks/api/app-start.json';
import AppSim from '../../../mocks/api/app-sim.json';
import ClientRegister from '../../../mocks/api/client-register.json';
import SimAgreementsWithSetup from '../../../mocks/api/sim-agreements-setup-true.json';
import SimAgreementsWithoutSetup from '../../../mocks/api/sim-agreements-setup-false.json';
import AgreementsWithSetup from '../../../mocks/api/agreements-setup-true.json';
import AgreementsSave from '../../../mocks/api/agreements-save.json';
import SimPuk from '../../../mocks/api/sim-puk.json';
import SessionLogin from '../../../mocks/api/session-login.json';
import Invoices from '../../../mocks/api/invoices.json';
import MoneyManagementSignature from '../../../mocks/api/sim-payment-signature.json';
import TopUpHistoryV3 from '../../../mocks/api/top-up-history-v3.json';
import TopUpHistoryV4 from '../../../mocks/api/top-up-history-v4.json';
import CostsTotalV3 from '../../../mocks/api/costs-total-v3.json';
import CostsTotalV4 from '../../../mocks/api/costs-total-v4.json';
import CostsDetailV3 from '../../../mocks/api/costs-detail-v3.json';
import CostsDetailV4 from '../../../mocks/api/costs-detail-v4.json';
import UsageTotal from '../../../mocks/api/usage-total.json';
import UsageDetail from '../../../mocks/api/usage-detail.json';
import Anchors from '../../../mocks/api/anchors.json';
import AnchorsToken from '../../../mocks/api/anchors-token.json';
import GigaFamily from '../../../mocks/api/giga-family.json';
import Activable from '../../../mocks/api/activable.json';
import ActivableNBANext from '../../../mocks/api/activablenba-next.json';
import ActivableNBACustomerNext from '../../../mocks/api/activablenba-customer-next.json';
import ActivableADDONMASSMARKETNext from '../../../mocks/api/activableaddonmassmarket-next.json';
import PerformNextSkipFalse from '../../../mocks/api/perform-next.json';
import PerformNextSkipTrue from '../../../mocks/api/perform-next-skip.json';
import PushActivationNext from '../../../mocks/api/push-activation-next.json';
import Perform from '../../../mocks/api/perform.json';
import Active from '../../../mocks/api/active.json';
import CheckActivation from '../../../mocks/api/check-activation.json';
import CheckDeactivation from '../../../mocks/api/check-deactivation.json';
import Product from '../../../mocks/api/product.json';
import BillinInfo from '../../../mocks/api/billing-info.json';
import PushCheckRtm from '../../../mocks/api/sim-checkRtm.json';
import PushPerformRtm from '../../../mocks/api/sim-performRtm.json';
import PushCheckCampaign from '../../../mocks/api/sim-checkCampaign.json';
import TopUpButton from '../../../mocks/api/top-up-button.json';
import Lineup from '../../../mocks/api/sim-lineup.json';
import autoTopup from '../../../mocks/api/auto-top-up.json';
import SetBillinInfo from '../../../mocks/api/set-billing-info.json';
import CardData from '../../../mocks/api/card-data.json';
import ActiveNext from '../../../mocks/api/active-next.json';
import DeactivationNext from '../../../mocks/api/deactivation-next.json';
import NewsExamplePage1 from '../../../mocks/api/newsPage1.json';
import NewsExamplePage2 from '../../../mocks/api/newsPage2.json';
import News from '../../../mocks/api/news.json';
import Scraping from '../../../mocks/api/scraping.json';
import IsJunior from '../../../mocks/api/isJunior.json';

export const mockApi = {
  // App
  '/api/v3/app/start': AppStart,
  '/api/v3/app/sim/\\w+$': AppSim,
  // Client
  '/api/v1/client/register': ClientRegister,
  // Puk
  '/api/v3/sim/\\w+/puk': SimPuk,
  // Consents
  '/api/v3/sim/\\w+/agreements\\?isSetup=true': SimAgreementsWithSetup,
  '/api/v3/sim/\\w+/agreements\\?isSetup=false': SimAgreementsWithoutSetup,
  '/api/v3/agreements/read': AgreementsWithSetup,
  '/api/v3/agreements/save': AgreementsSave,
  // Session
  '/api/v1/session/login': SessionLogin,
  // Invoices
  '/api/v3/\\w+/\\w+/invoices(.*)': Invoices,
  // MoneyManagement
  '/api/v3/sim/\\w+/payment/signature$': MoneyManagementSignature,
  // Anchors
  '/api/v3/anchors/lookup/(NA_RICARICA_ANONYMOUS_NO_OV|direct_na_ricaricaanonymousnoov).*': Anchors,
  '/api/v3/anchors/lookup/(NA_RICARICA_WITH_TOKEN_NO_OV|direct_na_ricaricawithtokennoov).*': AnchorsToken,
  // Giga Family
  '/api/v3/sim/\\w+/family': GigaFamily,
  // Catalog
  '/api/v4/sim/\\w+/offer/activable/\\w+': Product,
  '/api/v4/sim/\\w+/offer/activable': Activable,
  '/api/journeycatalog/v3/activableproducts\\?productType=NBA&sims=\\w+.*': ActivableNBANext,
  '/api/journeycatalog/v3/activableproducts\\?productType=NBA&sims=\\w+,\\w+&landlines=.*': ActivableNBACustomerNext,
  '/api/journeycatalog/v3/activableproducts\\?productType=ADDON&productType=MASSMARKET&sims=\\w+.*': ActivableADDONMASSMARKETNext,
  '/api/journeycatalog/v1/activableproducts\\?skipCheck=false&msisdn=\\w+': PerformNextSkipFalse,
  '/api/journeycatalog/v1/activableproducts\\?skipCheck=true&msisdn=\\w+': PerformNextSkipTrue,
  '/api/journeycatalog/v1/pushactivation\\?msisdn=\\w+': PushActivationNext, //PerformNextSkipFalse,
  '/api/v4/sim/\\w+/offer/performActivation': Perform,
  '/api/v4/sim/\\w+/offer/active/\\w+': Product,
  '/api/v4/sim/\\w+/offer/active': Active,
  '/api/v4/sim/\\w+/offer/checkActivation/\\w+': CheckActivation,
  '/api/v4/sim/\\w+/offer/checkDeactivation/\\w+': CheckDeactivation,
  '/api/v3/auth/customerInfo': IsJunior,
  // top-up-history
  '/api/v3/sim/\\w+/cost/topups': TopUpHistoryV3,
  '/api/v4/sim/\\w+/cost/topup': TopUpHistoryV4,
  // costs and usage
  '/api/v3/sim/\\w+/cost/total': CostsTotalV3,
  '/api/v4/sim/\\w+/cost/total': CostsTotalV4,
  '/api/v4/sim/\\w+/cost/usage': UsageTotal,
  '/api/v4/sim/\\w+/cost\\?s=\\d{4}-\\d{2}-\\d{2}&e=\\d{4}-\\d{2}-\\d{2}&mn=true&types=CALL&aggregation=true': UsageDetail,
  '/api/v3/sim/\\w+/cost': CostsDetailV3,
  '/api/v4/sim/\\w+/cost': CostsDetailV4,
  // billing info
  '/api/v3/sim/\\w+/billing/info': BillinInfo,
  // Push
  '/api/v4/sim/\\w+/offer/checkRtmActivation': PushCheckRtm,
  '/api/v4/sim/\\w+/offer/performRtmActivation': PushPerformRtm,
  '/api/v4/sim/\\w+/offer/checkCampaignActivation': PushCheckCampaign,
  // top-up-button
  '/api/v3/sim/\\w+/variableOffer/topupButton': TopUpButton,
  // Lineup
  '/api/v3/sim/\\w+/variableOffer/lineup/\\w+': Lineup,
  // auto-top-up
  '/api/v3/sim/\\w+/variableOffer/topup': autoTopup,
  // card data
  '/api/v3/sim/\\w+/payment/cardData': CardData,
  // set billing info
  '/api/v3/sim/\\w+/billing/paymentmethod': SetBillinInfo,
  // catalog next
  '/api/journeycatalog/v1/activeproducts\\?msisdn=\\w+': ActiveNext,
  '/api/journeycatalog/v1/activeproducts/\\w+\\?msisdn=\\w+': DeactivationNext,
  // news
  '/api/journeynews/v1/news\\?pages=1&categories=focused': NewsExamplePage1,
  '/api/journeynews/v1/news\\?pages=2&categories=focused': NewsExamplePage2,
  '/api/journeynews/v1/news\\?categories=\\w+': News,
  // Scraping
  '/api/v3/misc/scrape\\?\\w+': Scraping
};

