import { Inject, Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import { Observable } from 'rxjs';
import { ApiModule } from './api.module';
import { HttpParams } from '@angular/common/http';
import { CORE_CONFIGURATION } from '@myvf/core';

@Injectable({
  providedIn: ApiModule,
  deps: [BaseApiService]
})
export class CostsApiService {
  constructor(
    private http: BaseApiService,
    @Inject(CORE_CONFIGURATION) private config,
  ) {
  }

  /**
   * items api get residualCredit info
   * @param msisdn msisdn
   * @param noLoader boolean to don't show loader
   */
  retrieveResidualCredit(
    msisdn: string,
    noLoader: boolean = false
  ): Observable<any> {
    let params = new HttpParams()
      .set('sim', msisdn);
    if (noLoader) {
      params = params.set('noLoader', 'true');
    }

    return this.http.get(`/${this.config.environment}/costs/v1/retrieveResidualCredit`, params);
  }

}
